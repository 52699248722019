import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoMobile from './../../../assets/images/logo-mobile.png';
import Image from 'react-bootstrap/Image';
import {logOutApi, loginforadmin} from "../../../api/auth/Auth";
import {setStore} from "../../../store/reducers/App";
import Store from "../../../store";
import {isSubscribed} from "../../../utils/subscription";
import {
    create_quick_links,
    gas_quick_links,
    lottery_quick_links,
    other_quick_links
} from "../../../routing/routes/index";
import PermissionCheck from "../../../modules/Auth/PermissionCheck";
import TopNav from "../../../pages/partial/top-nav";
import {responseValidate} from '../../../utils/functions';
import { switchStoreApi } from '../../../api/auth/Auth';

const Header = ({toggleMenuSidebar, saving, userName, stores, store, user, segment}) => {

    const location = useLocation();

    const [savingState, setSavingState] = useState(undefined);

    useEffect(() => {
        if (location.pathname !== "/daily_report")
            setSavingState(undefined);
        else {
            if (saving)
                setSavingState(saving);
            else
                setTimeout(() => setSavingState(saving), 1000)
        }
    }, [location.pathname, saving]);

    const switchStore = async(row) => {
        
        let item = localStorage.getItem("location");
        await switchStoreApi(row.id);
        if (item) {
            localStorage.clear(); 
        }
        
        localStorage.setItem("location", row?.id);
        
        Store.dispatch(setStore(row));
        window.location.reload();
    }
    

    const switchAdmin = async () => {
        try {
            
            await loginforadmin('admin@hisably.com', user.roles[0])
                .then(responseValidate).then(result => {
                    if (result.status) {
                        window.location.reload();
                    }
                })
        } catch (error) {
          console.error('Failed to fetch:', error);
        }
    };

    return (
        <div className='topbar-nav'>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="hamburger-box"
                            onClick={() => toggleMenuSidebar()} role="button">
                            <div className="hamburger-inner"/>
                        </div>
                    </li>
                    <li className="nav-item d-md-none pl-2">
                        <Link to="/" className="text-center">
                            <Image src={LogoMobile} alt="Hisably Logo"/>
                        </Link>
                    </li>
                    {/* {
                        !isSubscribed(store) && <li className="nav-item d-none d-md-inline-block ">
                            <Button variant="danger top-nav-refer simple-btn">Subscription Ended Make Payment!</Button>
                        </li>
                    } */}

                    {
                        user.roles[0] !== 'Super Admin' &&
                        <li className="nav-item d-none d-md-inline-block ">
                            <Link to="/refer" className="btn btn-info top-nav-refer simple-btn">
                                Make Hisably FREE!
                            </Link>
                            {/* <Button variant="info top-nav-refer simple-btn">Make Hisably FREE!</Button> */}
                        </li>
                    }

                    {
                        (user.login_type == 'auto') &&
                            <li className="nav-item d-sm-inline-block customer-cupport-bar">
                                <Button variant="info top-nav-refer simple-btn" onClick={switchAdmin}>BACK TO ADMIN</Button>
                            </li>
                    }

                    {/* <li className="nav-item d-sm-inline-block customer-cupport-bar">
                        <span className="support-bar">Customer Support (8am to 10pm Mon-Fri) : (617) 683 1899</span>
                    </li> */}
                    {
                        savingState !== undefined &&
                        <li className="nav-item">
                        <span className="nav-link">
                            {
                                !savingState && <><i className="fas fa-cloud"/> Saved To Hisably</>
                            }
                            {
                                savingState && <><i className="fas fa-sync"/> Saving...</>
                            }
                        </span>
                        </li>
                    }
                </ul>

                {/* <form className="form-inline ml-3">
                    <div className="input-group input-group-sm">
                        <input className="form-control form-control-navbar" type="search" placeholder="Search"
                            aria-label="Search"/>
                        <div className="input-group-append">
                            <button className="btn btn-navbar" type="submit">
                                <i className="fas fa-search"/>
                            </button>
                        </div>
                    </div>
                </form> */}

                <ul className="navbar-nav flex-fill justify-content-end">
                    {/* <li className="nav-item staging-btn">
                        <span className="login-by-btn">STAGING</span>
                    </li> */}
                    {/*<li className="nav-item dropdown order-4 order-md-1">*/}
                    {/*    <Link to="#" data-toggle="dropdown" className="nav-link arrow">*/}
                    {/*        <span className="d-none d-md-inline-block">Message</span><i*/}
                    {/*        className="fas fa-angle-down d-none d-md-inline-block"/><i className="fas fa-comments d-md-none"/>*/}
                    {/*    </Link>*/}
                    {/*    <ul className="dropdown-menu dropdown-menu-right border-1">*/}
                    {/*        <li><Link to="#" className="dropdown-item"><i className="fas fa-list-ul"/> View All </Link></li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}

                    {
                        user.roles[0] !== 'Super Admin' &&
                        <li className="nav-item dropdown order-2 order-md-2">
                            <Link to="#" data-toggle="dropdown" className="nav-link arrow">
                                <span className="d-none d-md-inline-block">Quick Links</span><i className="fas fa-plus-circle"/>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-xxl dropdown-menu-right border-1 quick-link-dropdown">
                                <a className="close-drop"><i className="fas fa-times"/></a>
                                <Row>
                                    <Col>
                                        <h4>Lottery</h4>
                                        <div className="list-group">
                                            {
                                                lottery_quick_links.map(function (route, index) {
                                                    return <PermissionCheck key={index} permissions={route?.permissions}>
                                                        <Link to={route?.parent_path + route?.path} className="dropdown-item">
                                                            {route?.name}
                                                        </Link>
                                                    </PermissionCheck>
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        <h4>Create</h4>
                                        <div className="list-group">
                                            {
                                                create_quick_links.map(function (route, index) {
                                                    return <PermissionCheck key={index} permissions={route?.permissions}>
                                                        <Link to={route?.parent_path + route?.path} className="dropdown-item">
                                                            {route?.name}
                                                        </Link>
                                                    </PermissionCheck>
                                                })
                                            }
                                        </div>
                                    </Col>
                                    {
                                        store?.settings?.isGasReportDailyReport?.value != '0' &&
                                        <Col>
                                            <h4>Gas</h4>
                                            <div className="list-group">
                                                {
                                                    gas_quick_links.map(function (route, index) {
                                                        return <PermissionCheck key={index} permissions={route?.permissions}>
                                                            <Link to={route?.parent_path + route?.path}
                                                                className="dropdown-item">
                                                                {route?.name}
                                                            </Link>
                                                        </PermissionCheck>
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    }
                                    <Col>
                                        <h4>Other</h4>
                                        <div className="list-group">
                                            {
                                                other_quick_links.map(function (route, index) {
                                                    return <PermissionCheck key={index} permissions={route?.permissions}>
                                                        <Link to={route?.parent_path + route?.path} className="dropdown-item">
                                                            {route?.name}
                                                        </Link>
                                                    </PermissionCheck>
                                                })
                                            }
                                            <Button className="dropdown-item" onClick={() => {
                                                logOutApi().then(() => {
                                                    window.location.reload()
                                                })
                                            }}>
                                                Log Out
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </li>
                    }
                    <li className="nav-item dropdown order-3 order-md-3">
                        <Link to="#" data-toggle="dropdown" className="nav-link arrow">
                            <span className="d-none d-md-inline-block">
                                Hi, {userName.split(' ').slice(0, -1).join(' ')}&nbsp;
                            </span>
                            <i className="fas fa-angle-down d-none d-md-inline-block"/>
                            <i className="fas fa-user user-icon d-md-none"/>
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-right border-1">
                            <PermissionCheck permissions={['store_profile.page']}>
                                <li>
                                    <Link to='/settings/store-profile' className="dropdown-item">
                                        <i className="fas fa-unlock"/> Store Profile
                                    </Link>
                                </li>
                            </PermissionCheck>
                            <li>
                                <Link to="/change-password" className="dropdown-item">
                                    <i className="fas fa-unlock"/> Change Password
                                </Link>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => {
                                    logOutApi().then(() => {
                                        window.location.reload()
                                    })
                                }}>
                                    <i className="fas fa-sign-out-alt"/>Logout
                                </button>
                            </li>
                        </ul>
                    </li>
                    {
                        user.roles[0] !== 'Super Admin' &&
                        <li className="nav-item dropdown order-1 order-md-4">
                            <Link to="#" data-toggle="dropdown" className="nav-link arrow store-link">
                                <i className="fas fa-user user-icon d-none d-md-inline-block"/>
                                <i className="fas fa-store d-md-none"/>
                                <span>{store !== null ? store.name : "Select Store"}</span><i className="fas fa-angle-down"/>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-right border-1">
                                {
                                    stores.map(row => <li key={row.id}>
                                        <button className="dropdown-item" onClick={() => {
                                            if (row !== store) {
                                                switchStore(row);
                                            }
                                        }}>
                                            {row.name}
                                        </button>
                                    </li>)
                                }
                            </ul>
                        </li>
                    }
                </ul>
            </nav>
            {
                <TopNav navMenu={segment}/>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    saving: state.App.saving,
    stores: state.Auth.user.stores,
    userName: state.Auth.user.name,
    store: state.App.store,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(Header);
