import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Main from "./modules/main/Main";
import PrivateRouter from './routing/PrivateRouter'
import {connect} from "react-redux";
import Login from "./pages/auth/Login";
import PublicRoute from "./routing/PublicRoute";
import SplashScreen from "./modules/main/splash-screen/SplashScreen";
import {getUser} from "./api/auth/Auth";
import {responseValidate} from "./utils/functions";
import Store from "./store";
import {setStore,setGamesData,setStateCalculation} from "./store/reducers/App";
import {saveUserObject} from "./store/reducers/Auth";
import './styles/App.scss'
import GlobalToast from "./modules/Other/GlobalToast";
import {setStoreSettings} from "./store/reducers/StoreSettings";
import {AnimatePresence} from "framer-motion";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import 'react-dates/initialize';
import UnSubscribed from "./pages/auth/UnSubscribed";
import {isSubscribed} from "./utils/subscription";
import Print from "./pages/daily_report/Print";
import Refer from "./pages/refer/Refer";
import {app_timezone} from "./settings";
import moment from 'moment';
import timezone from 'moment-timezone';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactGA from 'react-ga4'; // Import react-ga4
import SubscriptionPage from "./pages/auth/SubscriptionPage";

function App({isLoggedIn, store}) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoggedIn) {
            // Initialize Google Analytics when the user is logged in
            ReactGA.initialize('UA-101467923-2'); // Replace with your GA4 Measurement ID
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
        }
    }, [isLoggedIn]);

    // press tab skip all disabled and redonly field 
    useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === 'Tab') {
            const focusableElements = [...document.querySelectorAll('input:not([disabled]):not([readonly]):not([hidden])')];
            const currentIndex = focusableElements.indexOf(document.activeElement);
            if (currentIndex !== -1) {
              const nextIndex = (currentIndex + (e.shiftKey ? -1 : 1) + focusableElements.length) % focusableElements.length;
              focusableElements[nextIndex].focus();
              e.preventDefault();
            }
          }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    moment.tz.setDefault(app_timezone);

    useEffect(() => {
        getUser().then(responseValidate).then(result => {
            setTimeout(() => {
                if (result.status) {
                    if (result.data.stores !== null && result.data.stores.length > 0) {
        
                        const stores_data = result.data.stores;

                        /* const stored_item = localStorage.getItem("location"); */
                        const stored_item = result.data.store_id;
                        
                        if (stores_data.length > 1 || stored_item) {
                            let matched_key = 0;
                            stores_data.map((value, key) => {
                                if (stored_item == value?.id)
                                    matched_key = key;
                            });

                            Store.dispatch(setStoreSettings(result.data.stores[matched_key].settings))
                            Store.dispatch(setStore(result.data.stores[matched_key]))
                            Store.dispatch(saveUserObject(result.data, true));
                            Store.dispatch(setStateCalculation(result.data.state_calculations))
                            Store.dispatch(setGamesData(result.data.games))
                        } else {
                            Store.dispatch(setStoreSettings(result.data.stores[0].settings))
                            Store.dispatch(setStore(result.data.stores[0]))
                            Store.dispatch(saveUserObject(result.data, true));
                        }
                    } else {
                        Store.dispatch(saveUserObject(result.data, false));
                    }
                }
                setLoading(false);
            }, 1000)
        }).catch(() => {
            setLoading(false);
        })
    }, [])

    if (loading)
        return <SplashScreen/>

    return <Router>
        <AnimatePresence>
            <Switch>
                <PublicRoute path='/login' component={Login}/>
                <PublicRoute path='/register' component={Register}/>
                <PublicRoute path='/forgot-password' component={ForgotPassword}/>
                <PublicRoute path='/reset-password' component={ResetPassword}/>
                {
                    isLoggedIn && 
                    <>
                        {
                            window.location.pathname == '/daily_report/print' ?
                                <>
                                    {
                                        !isSubscribed(store) && 
                                        <>
                                            <Route path='/' component={SubscriptionPage}/>
                                        </>
                                    }
                                    <Route exact strict path='/daily_report/print' component={Print}/>
                                    <PrivateRouter/>
                                    <GlobalToast/>
                                </>
                            :
                            <Main>
                                {
                                    !isSubscribed(store) && 
                                    <>
                                        <Route path='/' component={SubscriptionPage}/>
                                    </>
                                }
                                <Route path='/refer' component={Refer}/>
                                <PrivateRouter/>
                                <GlobalToast/>
                                
                                <TawkMessengerReact
                                    propertyId="5c40ce11ab5284048d0d6d36"
                                    widgetId="default"/>
                            </Main>
                        }
                    </>
                }
                {
                    !isLoggedIn &&
                    <Route render={props => <Redirect to={{pathname: "/login", from: props.location}}/>}/>
                }
            </Switch>
        </AnimatePresence>
    </Router>;
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
    store: state.App.store
})

export default connect(mapStateToProps)(App)
