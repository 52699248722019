import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Subscription from "../../api/settings/Subscription";
import { responseValidate } from "../../utils/functions";
import { connect } from "react-redux";
import PaymentMethod from "../../api/settings/PaymentMethod";
import {setNewToast} from "../../store/reducers/App";
import Store from '../../store';
import { useHistory, useLocation } from 'react-router-dom'; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const UpdateCardForm = ({ savedCards, onSelectCard, subscribeNow, store, setShowPaymentMethod}) => {
  const [selectedCard, setSelectedCard] = useState('');
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [newCard, setNewCard] = useState(false);  
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const handleCardChange = (e) => {
    const selectedCardId = e.target.value;
    setSelectedCard(selectedCardId);
    const cardDetails = savedCards.find(card => card.id === selectedCardId);
    setSelectedCardDetails(cardDetails);
    onSelectCard(selectedCardId);
    setErrorMessage('');
  };

  const handleToggleChange = (option) => {
    setNewCard(option === 'new');
    setSelectedCard('');  
    setSelectedCardDetails(null);
    setErrorMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear any previous error messages

    // Validate selection: Either a new card or a saved card must be chosen
    if (!newCard && !selectedCard) {
        setErrorMessage('Please select a saved card or enter new card details.');
        setLoading(false);
        return;
    }

    if (newCard && (!stripe || !elements)) {
        setErrorMessage('Stripe.js has not loaded properly.');
        setLoading(false);
        return;
    }

    if (newCard) {
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod: pm } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setErrorMessage(error.message);
            setLoading(false);
            return;
        }

        try {
            const response = await PaymentMethod.store({
                payment_method_id: pm.id,
                store_id: store?.id,
            });
            const result = responseValidate(response);
            if (result.status) {
                Store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'success'
                }));
                subscribeNow(pm.id);
            } else {
                Store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'danger'
                }));
            }
        } catch (err) {
            setErrorMessage('Failed to save new card. Please try again.');
        }
    } else {
        subscribeNow(selectedCard);
    }

    setLoading(false);
};

  return (
    <Form onSubmit={handleSubmit}>
      {/* Card Selection Section */}
      <Row className="mb-4">
        <Col>
          <div className="d-flex justify-content-between">
            {
              savedCards && savedCards.length > 0 && ( <Button 
                variant={newCard ? 'outline-primary' : 'primary'} 
                onClick={() => handleToggleChange('saved')} 
                style={{ flex: 1 }}
              >
                Use Saved Card
              </Button>)  
            }
           
            <Button 
              variant={newCard ? 'primary' : 'outline-primary'} 
              onClick={() => handleToggleChange('new')} 
              style={{ flex: 1 }}
            >
              Enter New Card
            </Button>
          </div>
        </Col>
      </Row>

      {/* Saved Cards Dropdown (if "Use Saved Card" is selected) */}
      {!newCard && savedCards.length > 0 && (
        <Form.Group className="mb-3">
          <Form.Label>Select a saved card</Form.Label>
          <Form.Control as="select" value={selectedCard} onChange={handleCardChange}>
            <option value="">Choose a card</option>
            {savedCards.map((card) => (
              <option key={card.id} value={card.id}>
                **** **** **** {card.card.last4} ({card.card.brand})
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      {selectedCardDetails && !newCard && (
        <div className="mb-3">
          <h6>Selected Card</h6>
          <p>Brand: {selectedCardDetails.card.brand}</p>
          <p>Last Four Digits: **** **** **** {selectedCardDetails.card.last4}</p>
          <p>Expiry: {selectedCardDetails.card.exp_month}/{selectedCardDetails.card.exp_year}</p>
        </div>
      )}

      {newCard && (
        <Form.Group className="mb-3">
          <Form.Label>Enter new card details</Form.Label>
          <div style={{ border: '1px solid #ced4da', borderRadius: '4px', padding: '10px' }}>
            <CardElement />
          </div>
        </Form.Group>
      )}

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <div className="d-flex justify-content-end align-items-center mt-3">
      <Button
        variant="secondary"
        onClick={() => setShowPaymentMethod(false)}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={loading}
        className="m-3" 
      >
        {loading ? 'Updating...' : 'Start Subscription'}
      </Button>
      
    </div>
    </Form>
    
  );
};



const SubscriptionPage = ({ store }) => {
  const [planPrice, setPlanPrice] = useState('');
  const [status, setStatus] = useState(store?.store_subscription?.stripe_status || null);
  const [billingCycle, setBillingCycle] = useState('Monthly');
  const [savedCards, setSavedCards] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState('');
  const [filteredPlan, setFilteredPlan] = useState(null);
  const [plan, setPlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const fetchSavedCards = useCallback(async () => {
    try {
      const response = await PaymentMethod.index({ store_id: store?.id });
      const result = responseValidate(response);
      if (result.status) setSavedCards(result.data);
    } catch (error) {
      console.error('Failed to fetch saved cards', error);
    }
  }, [store]);

  const fetchSubscriptions = useCallback(async () => {
    try {
      const response = await Subscription.index({ store_id: store?.id });
      const result = responseValidate(response);
      if (result.status) setSubscriptions(result?.data?.subscription);
    } catch (error) {
      console.error('Failed to fetch subscriptions', error);
    }
  }, [store]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchSavedCards(), fetchSubscriptions()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchSavedCards, fetchSubscriptions]);
  
  const subscribeNow = (paymentMethod) => {
    Subscription.store({
      store_id: store?.id,
      payment_card: paymentMethod,
      plan:filteredPlan?.id
    }).then(responseValidate).then(result => {
      if (result.status) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        Store.dispatch(setNewToast({
          body: result.message,
          variant: 'success'
        }));
      } else {
        Store.dispatch(setNewToast({
          body: result.message,
          variant: 'danger'
        }));
      }
    });
  };


  useEffect(() => {
    if (subscriptions && subscriptions.prices && subscriptions.prices.length > 0) {
      const selectedPlan = subscriptions.prices.find((price) => {
        if (billingCycle === 'Monthly' && price.recurring.interval === 'month') {
          return true;
        }
        if (billingCycle === 'Yearly' && price.recurring.interval === 'year') {
          return true;
        }
        return false;
      });
      if (selectedPlan) {
        setFilteredPlan(selectedPlan);
      } else {
        setFilteredPlan(null);
      }
    }
  }, [billingCycle, subscriptions]);


  useEffect(() => {
    if (store?.store_subscription && subscriptions?.prices) {
      const selectedPrice = subscriptions.prices.find(
        (price) => store.store_subscription.stripe_price === price.id
      );
      
      if (selectedPrice) {
        setPlanPrice(selectedPrice.unit_amount / 100); 
        setPlan(selectedPrice.id);
        setPlanDetails(selectedPrice.recurring.interval);
        setCurrentPlan(selectedPrice.recurring.interval === 'month' ? 'Monthly' : 'Yearly')
        if(selectedPrice.recurring.interval === 'month') setBillingCycle('Monthly'); else setBillingCycle('Yearly');
      }
    }
  }, [store?.store_subscription, subscriptions]);
  
  useEffect(() => {
    if (location.pathname !== '/') {
      history.push('/');
    }
  }, [location, history]);
  
  return (
    <Elements stripe={stripePromise}>
      <div className="container mt-4">
        <Card style={{ margin: '0 auto', borderRadius: '10px' }}>
          <Card.Body>
            <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Hisably Subscription</h5>
            {!showPaymentMethod &&  (<div style={{ marginBottom: '30px', padding: '20px', background: '#f8f9fa', borderRadius: '8px' }}>
            {store?.store_subscription && (
              <>
                <h6 style={{ fontWeight: 'bold',color:'red' }}> Subscription ended! Please renew now to continue using Hisably.</h6>
                <hr />
                <div style={{ textAlign: 'left',fontSize:'15px' }}>
                <p ><strong>Existing Plan:</strong> {currentPlan ? currentPlan: 'Not Available'}</p>
                  
                  {/* Highlight Status */}
                  <p>
                    <strong>Status:</strong> 
                    <span 
                      style={{
                        fontWeight: 'bold', 
                        color: status === 'active' ? 'green' : 'red', 
                        backgroundColor: status === 'active' ? '#e8f5e9' : '#f8d7da', 
                        padding: '4px 8px', 
                        borderRadius: '4px',
                        marginLeft:'5px'
                      }}
                    >
                    {status || 'Not Available'}
                    </span>
                  </p>

                  <p><strong>Price:</strong> ${planPrice}</p>
                </div>
                </>
             
            )}
          <hr></hr>
          <div className="subscription-container">
            <Row className='justify-content-center'>
            {
              subscriptions?.prices?.map(row => {
                
                if (planDetails && planDetails === 'year' && row.recurring.interval === 'month') {
                  return null;  
                }
                
                return (
                  <Col key={row.id} md={4}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        onChange={e => {
                          if (e.target.checked) {
                            setPlan(row.id);
                            setBillingCycle(row.recurring.interval === 'month' ? 'Monthly' : 'Yearly');
                          }
                        }}
                        name="planRadios"
                        id={row.id}
                        checked={plan === row.id && billingCycle === (row.recurring.interval === 'month' ? 'Monthly' : 'Yearly')}
                      />
                      <label className="form-check-label subscription-box-label shadow-2" htmlFor={row.id}>
                        <h3 className="subscription-title" style={{ textTransform: 'capitalize' }}>
                          {row.recurring.interval}ly Plan
                        </h3>
                        <span className="subscription-price">
                          ${row.unit_amount_decimal.substring(0, row.unit_amount_decimal.length - 2)}/{row.recurring.interval}
                        </span>
                        {
                          row.recurring.interval === 'month' ?
                          <span className="subscription-detail">It's only $1 per Day!</span> :
                          <span className="subscription-detail">Go Annual and get 10% discount!</span>
                        }
                        <div className="subscription-btn">Select Plan</div>
                      </label>
                    </div>
                  </Col>
                );
              })
            }

            </Row>
          </div>

            {filteredPlan && (
              <div className="mt-4 d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={() => setShowPaymentMethod(true)} // Show payment method section
                  >
                    Next
                  </Button>
              </div>
            )}
             </div>)}
            {showPaymentMethod && (
                <Row className="mt-4">
                  {/* Display the selected plan details */}
                  <Col xs={12} md={6} className="mb-4" style={{borderRadius: '8px' }}>
                    {filteredPlan && (
                      <div style={{ marginTop: '', padding: '20px', background: '#f8f9fa', borderRadius: '8px' }}>
                        <h5 
                          style={{
                            fontWeight: 'bold',
                            color: '#333',
                            marginBottom: '1rem',
                          }}
                        >
                          Selected Plan
                        </h5>
                        <p 
                          style={{
                            fontSize: '1rem',
                            margin: '0.5rem 0',
                            color: '#555',
                          }}
                        >
                          <strong>Plan:</strong> {billingCycle}
                        </p>
                        <p 
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            margin: '0.5rem 0',
                            color: '#555', // Blue to indicate price
                          }}
                        >
                          <strong>Price:</strong> <span style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            margin: '0.5rem 0',
                            color: '#007bff', // Blue to indicate price
                          }}>${filteredPlan.unit_amount / 100}</span>
                        </p>
                      </div>
                    )}
                  </Col>

                  <Col xs={12} md={6} className='mb-4'>
                    {savedCards && (
                      <div style={{ marginTop: '', padding: '20px', background: '#f8f9fa', borderRadius: '8px', }}>
                        <h6 style={{ fontWeight: 'bold' }}>Payment Method</h6>
                        <UpdateCardForm savedCards={savedCards} onSelectCard={setSelectedCard} subscribeNow={subscribeNow} store={store} setShowPaymentMethod={setShowPaymentMethod}/>
                      </div>
                    )}
                  </Col>
                  {/* <Col xs={12} md={6} className="mb-4">
                    <Button
                      variant="primary"
                      onClick={() => setShowPaymentMethod(false)} // Show payment method section
                    >
                      Back
                    </Button>
                  </Col> */}
                </Row>
              )}
          </Card.Body>
        </Card>
      </div>
    </Elements>
  );
};


const mapStateToProps = state => ({
  store: state.App.store,
});

export default connect(mapStateToProps)(SubscriptionPage);

